<template>
  <div v-if="activeBooking" class="booking-details-summary">
    <div>
      <AppLabel :label="data.title" :label-right="data.flight.date" />
      <div>
        <template
          v-for="(segment, index) in data.flight.direction.segmentsList"
        >
          <BookingFlightCardDetails
            :key="index"
            :segment="segment"
            :booking="activeBookingDetails.booking"
            info-label="Flight reservation details"
            show-card
          />
          <div
            v-if="index !== data.flight.direction.segmentsList.length - 1"
            style="
              display: grid;
              grid-template-columns: 3px 1fr;
              padding: 0 2rem;
            "
          >
            <AppDashedLine direction="vertical" />
            <div
              style="
                padding: 1.33rem;
                display: flex;
                align-items: center;
                font-size: 15px;
                font-family: DDin;
              "
            >
              <IconClock />
              <p style="margin-left: 0.5rem">{{ getLayover(index) }}</p>
            </div>
          </div>
        </template>
      </div>
    </div>
    <div>
      <AppLabel label="RESERVATION DETAILS" />
      <SettingsCards :data="data.flight.reservationDetails" small />
    </div>
    <div>
      <AppLabel label="PASSENGERS" />
      <SettingsCards :data="data.flight.passengers" small />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import AppLabel from '../../components-rf/AppLabel.vue'
import BookingFlightCardDetails from '../../components-rf/bookings/BookingFlightCardDetails.vue'
import SettingsCards from '../../components/common/SettingsCards.vue'
import IconClock from '../../components-rf/icons/common/IconClock.vue'
import AppDashedLine from '../../components-rf/AppDashedLine.vue'
import { date } from '../../other/utils/helpers/date'

export default {
  name: 'BookingDetailsFlight',
  components: {
    AppLabel,
    SettingsCards,
    BookingFlightCardDetails,
    IconClock,
    AppDashedLine,
  },
  computed: {
    ...mapState('BookingModule', ['activeBooking']),
    ...mapGetters('BookingModule', ['activeBookingDetails']),
    data() {
      return {
        departure: {
          title: 'DEPARTURE FLIGHT',
          flight: this.activeBookingDetails.departureFlight,
        },
        return: {
          title: 'RETURN FLIGHT',
          flight: this.activeBookingDetails.returnFlight,
        },
      }[this.$route.params.type]
    },
  },
  methods: {
    getLayover(segmentIndex) {
      const current = this.data.flight.direction.segmentsList[segmentIndex]
      const next = this.data.flight.direction.segmentsList[segmentIndex + 1]

      const arrivalTimestamp = current.arrivalDateTimeTimestamp.seconds * 1000
      const departureTimestamp = next.departureDateTimeTimestamp.seconds * 1000
      const duration = date.prettyDuration('-dd -hh -minm')(
        departureTimestamp - arrivalTimestamp,
      )

      const city = current.arrivalAirport?.city.name

      return `Layover: ${duration} in ${city}`
    },
  },
}
</script>

<style scoped lang="scss">
.booking-details-summary {
  display: grid;
  gap: 1rem;
  & > div {
    display: grid;
    gap: 0.5rem;
  }
}
</style>
