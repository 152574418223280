<template>
  <div>
    <p v-if="title" style="margin-bottom: 1rem">{{ title }}</p>
    <div class="x-wrap">
      <div
        v-for="(el, index) in data"
        :key="index"
        v-bind="el"
        class="x-card"
        :data-clickable="typeof el.click === 'function'"
        :disabled="el.disabled"
        :class="classes"
        @click="onClick(el)"
      >
        <flex v-if="el.icons && el.icons.length > 0" gap="1">
          <icon
            v-for="(ico, iconIndex) in el.icons"
            :key="iconIndex"
            :icon="ico"
          />
        </flex>
        <p v-if="el.label" class="x-label" :data-label-color="el.optLabelColor">
          {{ el.label }}
        </p>
        <p v-if="el.value !== undefined && !el.label" class="x-value">
          {{ el.value }}
        </p>
        <grid
          gap="0.5"
          gtc="auto auto"
          gta="'value arrow'"
          style="margin-left: auto"
        >
          <p
            v-if="el.value !== undefined && el.label"
            class="x-value"
            style="grid-area: value; text-align: end"
          >
            {{ el.value }}
          </p>
          <p
            v-if="!el.value && el.info && el.label"
            class="x-value-info"
            style="grid-area: value"
          >
            {{ el.info }}
          </p>
          <icon
            v-if="el.click && !noArrow"
            icon="DropdownRight"
            style="grid-area: arrow"
            color="#333"
          />
        </grid>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SettingCards',
  props: {
    data: Array,
    title: String,
    small: Boolean,
    noArrow: Boolean,
  },
  methods: {
    onClick(el) {
      if (el.click && !el.disabled) el.click()
      else return () => {}
    },
  },
  computed: {
    classes() {
      const classes = []
      classes.push(this.small ? 'x-card-small' : 'x-card-default')
      return classes
    },
  },
}
</script>

<style scoped lang="scss">
.x-card-small {
  padding: 1rem 1.5rem;
}
.x-card-default {
  padding: 1.5rem;
}
.x-card {
  grid-template-columns: 1fr auto;
  display: grid;
  align-items: center;
  transition: all 0.2s ease-in-out;
  border-bottom: 0.5px solid #eee;
  background: white;
  &:first-child {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }
  &:last-child {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    border-bottom: none;
  }
  > * {
    pointer-events: none;
  }
}
.x-card-grey {
  background: #e9e9e9;
}
.x-card {
  box-shadow: var(--shadow-app);
}
.x-card[data-clickable]:not([disabled]) {
  color: var(--color-dark);
  background: var(--color-white);
  &:hover {
    background: darker(get('color.white'), 1%);
  }
  &:active {
    background: darker(get('color.white'), 5%);
  }
  cursor: pointer;
  &:hover {
    box-shadow: var(--shadow-app-2);
  }
}
.x-card[disabled] {
  background: #eaeaea !important;
}
.x-wrap {
  border-radius: var(--border-radius-m);
}
.x-label {
  color: #333;
  &[data-label-color='red'] {
    color: var(--color-primary);
  }
  font-family: DDin;
  font-size: 16px;
  letter-spacing: 0.2px;
}
.x-value {
  font-family: DDin;
  color: #333;
  font-size: 16px;
  letter-spacing: 0.2px;
}
.x-value-info {
  font-family: DDin;
  color: #a0a0a0;
  font-size: 1rem;
  letter-spacing: 0.2px;
}
</style>
